.toastContainer {
  display: flex;
  align-items: center;
  gap: 30px;
}

.toastContainer p {
  margin: 0;
  font-weight: 600;
}

.closeIcon {
  cursor: pointer;
}