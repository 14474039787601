.loginContent {
	padding: 24px 0;
}

.titleGroup {
	margin: 2rem 0 4rem 0;
}

.banner {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.card {
	width: 270px;
	display: inline-block;
	box-shadow: 0 1px 10px rgba(0,0,0,0.25);
}

.bar {
	height: 300px;
	flex: 1;
	background-color: #f2f2f7;
	align-self: center;
}

.left {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.right {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.signupDivider {
	margin: 2rem 0 2rem 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.signupDivider .line {
	height: 2px;
	background-color: #e1e3e7;
	flex: 1;
}

.signupDivider .instruction {
	color: #aeaeb2;
	font-size: 12px;
	margin: 0 12px 0 12px;
	line-height: 1;
}

.buttonGroup {
	display: grid;
	grid-template-columns: 1;
	row-gap: 16px;
	justify-content: center;
	margin-top: 4rem;
}

.signupButton {
	width: 330px;
	border: 1px solid transparent;
}

.emailSignupButton,
.googleSignupButton {
	background-color: #fff;
	color: #636366;
	border-color: #e1e3e7;
}

.appleSignupButton {
	background-color: #000;
	color: #fff;
}

.promptContainer {
	margin-top: 1.5rem;
}
.prompt {
	font-size: 12px;
	color: #aeaeb2;
	text-align: center;
	margin: 0;
}

.promptLink {
	color: #007aff;
	cursor: pointer;
	font-weight: 700;
}

.centerError {
	display: flex;
	justify-content: center;
	padding: 12px 0;
}

.logoSvg {
	max-width: 290px;
	margin-bottom: 12px;
}

.titleGroup {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media only screen and (max-width: 576px) {
	.titleGroup {
		margin: 30px 0 10px;
	}

	.buttonGroup {
		margin-top: 10px;
	}

	.card {
		width: 220px;
	}

	.banner {
		margin: 40px auto;
	}
}

@media only screen and (max-width: 280px) {
	.logoSvg {
			max-width: 250px;
			margin-bottom: 12px;
	}

	.card {
		width: 270px;
	}

	.titleGroup {
		margin: 10px 0;
	}

	.buttonGroup {
		margin-top: 0px;
	}
}