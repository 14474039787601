.form {
	display: flex;
}

.formFieldContainer {
	width: 350px;
	margin: 0 auto;
	display: grid;
	justify-content: center;
	row-gap: 15px;
}

.formButton {
	margin-top: 1rem;
	background-color: #007aff;
	color: #fff;
	font-weight: 700;
	width: 100%;
}

.tAndCCheckbox, .pwInstruct {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font-size: 12px;
	color: #aeaeb2;
	text-align: center;
	margin: 0;
}

.byClickingSignUp {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.5);
	text-align: center;
	margin: 0;
}

.byClickingSignUp span {
	color: #007aff;
	font-weight: 600;
}

.byClickingSignUp span:hover {
	cursor: pointer;
	text-decoration: underline;
}

.pwInstruct {
	text-align: left;

}

@media only screen and (max-width: 576px) {
	.form {
		margin-top: 40px;
	} 
}